<template>
  <!--v-if="precioInitial > 0"-->
  <!--v-if="!isLoading"-->
  <div
    class="d-none d-md-block"
  >
    <!--  style="position: fixed; z-index: 6; width: available" -->
    <v-col
      ref="statiCard"
      cols="12"
      md="12"
      class=""
    >
      <!--CALCULADORA-->
      <v-row
        v-if="showOnlyGestor"
        class="mb-3"
      >
        <v-card
          width="100%"
          class="mx-2 pa-4 pt-0"
        >
          <v-row>
            <v-col
              cols="4"
              class="pt-2"
            >
              <h1
                primary-title
                class="pa-0"
              >
                PVP
              </h1>
            </v-col>
            <v-col
              cols="8"
              class="pt-5 text-end"
            >
              <AppBarCurrencyPVP />
              <!--<span
                style="font-size: 12px;"
              >
                ...{{ $t('lbl.facturar') }} {{ currency_symbol }} {{ currency_code }}
              </span>-->
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="4"
            >
              <v-row class="d-flex justify-center align-center">
                <div class="d-block">
                  <h4 class="p12Mine d-flex justify-center align-center">
                    {{ $t('lbl.precioNeto') }}
                  </h4>
                  <v-divider></v-divider>
                  <p class="p12Mine mb-0 d-flex justify-center align-center">
                    <v-tooltip
                      top
                      color="primary"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ precioNeto | currency(cotizadorHotelsResultSelect.contrate_currency_symbol) }}
                        </span>
                      </template>
                      <span>
                        1 {{ cotizadorHotelsResultSelect.currencyBaseCode }} = {{ tasaCambioContrate }} {{ cotizadorHotelsResultSelect.contrate_currency_code }}
                      </span>
                    </v-tooltip>
                  </p>
                </div>
              </v-row>
            </v-col>
            <v-col
              cols="4"
            >
              <!--<v-row class="d-flex justify-center align-center">
                <div class="d-block">
                  <h4 class="p12Mine d-flex justify-center align-center">
                    {{ $t('lbl.tasa') }}
                  </h4>
                  <v-divider></v-divider>
                  <p class="p12Mine mb-0 d-flex justify-center align-center">
                    <v-tooltip
                      top
                      color="primary"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ exchangeRateCurrencyFacturar | number('0.0000') }}
                        </span>
                      </template>
                      <span>
                        1 {{ currencyCodeFacturar }} = {{ exchangeRateCurrencyFacturar | number('0.0000') }} {{ cotizadorHotelsResultSelect.contrate_currency_code }}
                      </span>
                    </v-tooltip>
                  </p>
                </div>
              </v-row>-->
            </v-col>
            <v-col
              cols="4"
              class="text-end"
            >
              <v-row class="d-flex justify-end align-end mr-1">
                <div class="d-block">
                  <h4 class="p12Mine d-flex justify-end align-end">
                    {{ $t('lbl.precioInitial') }}
                  </h4>
                  <v-divider></v-divider>
                  <p class="p12Mine mb-0 d-flex justify-end align-end">
                    <!--{{ precioInitial | currency(currencySymbolFacturar) }}-->
                    <v-tooltip
                      top
                      color="primary"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ precioInitial | currency(currencySymbolFacturar) }}
                        </span>
                      </template>
                      <span>
                        1 {{ cotizadorHotelsResultSelect.currencyBaseCode }} = {{ tasaCambioFacturar }} {{ currencyCodeFacturar }}
                      </span>
                    </v-tooltip>
                  </p>
                </div>
              </v-row>
            </v-col>
          </v-row>

          <!--MARKUPS-->
          <v-row class="pt-2">
            <v-col
              cols="4"
              class="pr-0"
            >
              <v-text-field
                v-model="markup"
                label="Mark-up"
                hide-details
                outlined
                width="auto"
                class="descuento"
                type="number"
                :disabled="isLoading"
                @change="validateMarkupsInput()"
              >
              <!--input.native-->
              </v-text-field>
            </v-col>
            <v-col
              cols="4"
              class="pr-0"
            >
              <v-radio-group
                v-model="valueMarkup"
                hide-details
                dense
                class="mt-0 pt-0 descuento"
                :disabled="isLoading"
                @change="validateMarkupsRadio()"
              >
                <v-radio
                  label="%"
                  class="descuento"
                  value="%"
                ></v-radio>
                <v-radio
                  :label="currencyCodeFacturar"
                  class="descuento"
                  :value="currencyCodeFacturar"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col
              cols="4"
              class="text-end"
            >
              <h4 class="p12Mine">
                {{ $t('lbl.conMarkups') }}
              </h4>
              <v-divider></v-divider>
              <p class="p12Mine">
                {{ precioWithMarkup | currency(currencySymbolFacturar) }}
              </p>
            </v-col>
          </v-row>
          <v-row class="mt-0 pt-0">
            <v-col
              cols="8"
              class="mt-0 pt-0"
            >
              <span>
                <span style="font-size: 10px">
                  {{ $t('lbl.incluyeMargenCom') }}
                </span>
                <fragment
                  v-if="markup !== 0 && valueMarkup !== null"
                >
                  <span style="font-size: 12px">
                    ({{ markup }}
                    <span v-if="valueMarkup === '%'">%</span>
                    <span v-else-if="valueMarkup === currencyCodeFacturar">{{ currencyCodeFacturar }}</span>)
                  </span>
                </fragment>
                <!--<fragment
                  v-if="cotizadorHotelsSelectMarkups.markup !== 0 && cotizadorHotelsSelectMarkups.valueMarkup !== null"
                >
                  <span style="font-size: 12px">
                    ({{ cotizadorHotelsSelectMarkups.markup }}
                    <span v-if="cotizadorHotelsSelectMarkups.valueMarkup === '%'">%</span>
                    <span v-else-if="cotizadorHotelsSelectMarkups.valueMarkup === currencyCodePVP">{{ currencyPVP }}</span>)
                  </span>
                </fragment>-->
              </span>
            </v-col>
            <v-col
              cols="4"
              class="mt-0 pt-0 text-end"
              style="text-align: center;"
            >
              <span style="font-size: 12px">
                {{ valueMarkupCalculate | currency(currencySymbolFacturar) }}
                <!--{{ priceMarkup | currency(currencyCodePVP) }}-->
              </span>
            </v-col>
          </v-row>

          <!--DESCUENTO-->
          <v-row
            v-if="agregarDescuento"
            class="pt-2"
          >
            <v-col
              cols="4"
              class="pr-0"
            >
              <v-text-field
                v-model="descuento"
                class="descuento"
                :label="$t('lbl.descuento')"
                hide-details
                outlined
                width="auto"
                type="number"
                @change="validateDescuentoInput"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="4"
              class="pr-0"
            >
              <v-radio-group
                v-model="valueDescuento"
                hide-details
                dense
                class="mt-0 pt-0"
              >
                <v-radio
                  label="%"
                  class="descuento"
                  value="%"
                ></v-radio>
                <v-radio
                  :label="currencyCodeFacturar"
                  class="descuento"
                  :value="currencyCodeFacturar"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col
              cols="4"
              class="text-end"
            >
              <h4 class="p12Mine">
                {{ $t('lbl.conDescuento') }}
              </h4>
              <v-divider></v-divider>
              <p class="p12Mine mb-0">
                {{ precioWithDescuento | currency(currencySymbolFacturar) }}
              </p>
            </v-col>
          </v-row>
          <v-row
            v-if="agregarDescuento && valueDescuentoCalculate > 0"
            class="mt-0 pt-0"
          >
            <v-col
              cols="8"
              class="mt-0 pt-0"
            >
              <span>
                <span style="font-size: 10px">
                  {{ $t('lbl.incluyeDescuento') }}
                </span>
                <fragment
                  v-if="
                    descuento !== 0 && valueDescuento !== null
                  "
                >
                  <span style="font-size: 12px">
                    ({{ descuento }}
                    <span v-if="valueDescuento === '%'">%</span>
                    <span v-else-if="valueDescuento === currencyCodeFacturar">{{ currencyCodeFacturar }}</span>)
                  </span>
                </fragment>
                <!--<fragment
                  v-if="
                    cotizadorHotelsSelectMarkups.descuento !== 0 && cotizadorHotelsSelectMarkups.valueDescuento !== null
                  "
                >
                  <span style="font-size: 12px">
                    ({{ cotizadorHotelsSelectMarkups.descuento }}
                    <span v-if="cotizadorHotelsSelectMarkups.valueDescuento === '%'">%</span>
                    <span v-else-if="cotizadorHotelsSelectMarkups.valueDescuento === currencyCodePVP">{{ currencyPVP }}</span>)
                  </span>
                </fragment>-->
              </span>
            </v-col>
            <v-col
              cols="4"
              class="mt-0 pt-0 text-end"
              style="text-align: center;"
            >
              <span style="font-size: 12px">
                {{ valueDescuentoCalculate | currency(currencySymbolFacturar) }}
                <!--{{ priceDescuento | currency(currencyCodePVP) }}-->
              </span>
            </v-col>
          </v-row>

          <!--BTN-->
          <v-row>
            <v-col cols="7">
              <v-checkbox
                v-model="agregarDescuento"
                :label="$t('lbl.addDescuento')"
                dense
                hide-details
                outlined
                class="descuento my-0 py-0"
                value="1"
                :disabled="isLoading"
                @change="changeAgregarDescuento"
              ></v-checkbox>
            </v-col>
            <v-col
              cols="4"
              class="align-self-center align-right"
            >
              <v-btn
                color="primary"
                :disabled="isLoading"
                @click="applyMarkups"
              >
                {{ $t('btn.apply') }}
              </v-btn>
            </v-col>
          </v-row>

          <!--VERSION VIEJA
            <v-row>
            <v-col
              cols="12"
              md="7"
            >
              <v-col
                cols="12"
                class="py-0"
              >
                <h1
                  primary-title
                  class="pa-0"
                >
                  PVP
                </h1>
              </v-col>
              <v-row class="pt-2">
                <v-col
                  cols="7"
                  class="pr-0"
                >
                  <v-text-field
                    v-model="markup"
                    label="Mark-up"
                    hide-details
                    outlined
                    width="auto"
                    class="descuento"
                    type="number"
                    :disabled="isLoading"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  cols="5"
                  class="pr-0"
                >
                  <v-radio-group
                    v-model="valueMarkup"
                    hide-details
                    dense
                    class="mt-0 pt-0 descuento"
                    :disabled="isLoading"
                  >
                    <v-radio
                      label="%"
                      class="descuento"
                      value="%"
                    ></v-radio>
                    <v-radio
                      :label="currencyPVP"
                      class="descuento"
                      :value="currencyCodePVP"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  v-if="agregarDescuento"
                  cols="7"
                  class="pr-0"
                >
                  <v-text-field
                    v-model="descuento"
                    class="descuento"
                    :label="$t('lbl.descuento')"
                    hide-details
                    outlined
                    width="auto"
                    type="number"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  v-if="agregarDescuento"
                  cols="5"
                  class="pr-0"
                >
                  <v-radio-group
                    v-model="valueDescuento"
                    hide-details
                    dense
                    class="mt-0 pt-0"
                  >
                    <v-radio
                      label="%"
                      class="descuento"
                      value="%"
                    ></v-radio>
                    <v-radio
                      :label="currencyPVP"
                      class="descuento"
                      :value="currencyCodePVP"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10">
                  <v-checkbox
                    v-model="agregarDescuento"
                    :label="$t('lbl.addDescuento')"
                    dense
                    hide-details
                    outlined
                    class="descuento my-0 py-0"
                    value="1"
                    :disabled="isLoading"
                    @change="changeAgregarDescuento"
                  ></v-checkbox>
                </v-col>
                <v-col
                  cols="2"
                  class="align-self-center align-right"
                >
                  <v-btn
                    color="primary"
                    :disabled="isLoading"
                    @click="applyMarkups"
                  >
                    {{ $t('btn.apply') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              md="5"
              class="mt-12"
            >
              <v-row class="d-flex justify-center align-center">
                <div class="d-block">
                  <h4 class="p12Mine d-flex justify-center align-center">
                    {{ $t('lbl.precioInitial') }}
                  </h4>
                  <v-divider></v-divider>
                  <p class="p12Mine mb-0 d-flex justify-center align-center">
                    <v-tooltip
                      v-if="cotizadorHotelsResultSelect.change_currency.change"
                      top
                      color="primary"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ precioInitial | currency(currencyCodePVP) }}
                        </span>
                      </template>
                      <span>
                        {{ $t('lbl.neto') }}:
                        {{ cotizadorHotelsResultSelect.change_currency.change | number('0.0000') }}
                      </span>
                    </v-tooltip>
                    <span v-else>{{ precioInitial | currency(currencyCodePVP) }}</span>
                  </p>
                </div>
              </v-row>
              <v-row class="mt-6 d-flex justify-center align-center">
                <div class="d-block">
                  <h4 class="p12Mine d-flex justify-center align-center">
                    {{ $t('lbl.conMarkups') }}
                  </h4>
                  <v-divider></v-divider>
                  <p class="p12Mine mb-0 d-flex justify-center align-center">
                    {{ precioWithMarkup | currency(currencyCodePVP) }}
                  </p>
                </div>
              </v-row>
              <v-row
                v-if="agregarDescuento"
                class="mt-6 d-flex justify-center align-center"
              >
                <div class="d-block">
                  <h4 class="p12Mine d-flex justify-center align-center">
                    {{ $t('lbl.conDescuento') }}
                  </h4>
                  <v-divider></v-divider>
                  <p class="p12Mine mb-0 d-flex justify-center align-center">
                    {{ precioWithDescuento | currency(currencyCodePVP) }}
                  </p>
                </div>
              </v-row>
            </v-col>
          </v-row>-->
        </v-card>
      </v-row>

      <template v-if="!isLoading">
        <!--MOSTRAR PARA EL GESTOR-->
        <template v-if="showOnlyGestor">
          <v-row class="my-2">
            <!--TARIFAS-->
            <v-card
              width="100%"
              class="mx-2 pa-4 pt-0"
            >
              <div class="d-flex justify-space-between align-center">
                <h4 class="my-1">
                  {{ $t('lbl.tarif') }}
                </h4>
                <h4>
                  <h4>
                    {{ precioTotal | currency(currencySymbolFacturar) }}
                  </h4>
                </h4>
              </div>
              <fragment
                v-for="(room, iRom) in cotizadorHotelsRomms"
                :key="iRom"
              >
                <fragment v-if="!showOcupationRoomHotel && room.room_id">
                  <div class="d-flex justify-space-between align-center mb-0 pb-0">
                    <span
                      class="mb-0 pb-0"
                      style="font-size: 14px"
                    >
                      {{ room.cant }} x
                      {{
                        roomsNomAll.filter(e => e.id === room.room_id).length > 0
                          ? roomsNomAll.filter(e => e.id === room.room_id)[0].name
                          : ''
                      }}
                    </span>
                    <span style="font-size: 14px"> {{ room.priceWithDescuento | currency(currencySymbolFacturar) }} </span>
                  </div>
                  <div class="mt-0 pt-0">
                    <span
                      class="pt-0 mt-0 ml-5"
                      style="font-size: 10px"
                    >
                      {{ $t('lbl.plan') }}:
                      <span v-if="regimenNomAll.filter(e => e.id === room.plan).length > 0">
                        {{ regimenNomAll.filter(e => e.id === room.plan)[0].siglas }}
                      </span>
                      <span class="ml-1">{{ $t('lbl.uso') }}: </span>
                      <span class="text-uppercase">{{ room.uso }}</span>
                      <span v-if="room.cantTeenEdad + room.cantMnrEdad + room.cantInfEdad > 0">+ {{ $tc('lbl.cantChild', (room.cantTeenEdad + room.cantMnrEdad + room.cantInfEdad)) }}</span>
                    </span>
                  </div>
                </fragment>
              </fragment>
              <!--<div class="d-flex justify-space-between align-center ml-2 mt-3">
                <span>
                  <span style="font-size: 14px">
                    {{ $t('lbl.incluyeMargenCom') }}
                  </span>
                  <fragment
                    v-if="cotizadorHotelsSelectMarkups.markup !== 0 && cotizadorHotelsSelectMarkups.valueMarkup !== null"
                  >
                    <span style="font-size: 14px">
                      ({{ cotizadorHotelsSelectMarkups.markup }}
                      <span v-if="cotizadorHotelsSelectMarkups.valueMarkup === '%'">%</span>
                      <span v-else-if="cotizadorHotelsSelectMarkups.valueMarkup === currencyCodePVP">{{ currencyPVP }}</span>)
                    </span>
                  </fragment>
                </span>
                <span style="font-size: 14px">
                  {{
                    priceMarkup | currency(currencyCodePVP)
                  }}
                </span>
              </div>
              <div class="d-flex justify-space-between align-center ml-2 mt-0">
                <span>
                  <span style="font-size: 14px">
                    {{ $t('lbl.incluyeDescuento') }}
                  </span>
                  <fragment
                    v-if="
                      cotizadorHotelsSelectMarkups.descuento !== 0 && cotizadorHotelsSelectMarkups.valueDescuento !== null
                    "
                  >
                    <span style="font-size: 14px">
                      ({{ cotizadorHotelsSelectMarkups.descuento }}
                      <span v-if="cotizadorHotelsSelectMarkups.valueDescuento === '%'">%</span>
                      <span v-else-if="cotizadorHotelsSelectMarkups.valueDescuento === currencyCodePVP">{{ currencyPVP }}</span>)
                    </span>
                  </fragment>
                </span>
                <span style="font-size: 14px">
                  {{
                    priceDescuento | currency(currencyCodePVP)
                  }}
                </span>
              </div>-->
            </v-card>

            <!--SUPLEMENTOS-->
            <v-card
              width="100%"
              class="mx-2 mt-3 pa-4 pt-0"
            >
              <div class="d-flex justify-space-between align-center">
                <h4 class="my-1">
                  {{ $t('lbl.cargoSuplement') }}
                </h4>
                <h4>
                  <h4>
                    {{ 0 | currency(currencySymbolFacturar) }}
                  </h4>
                </h4>
              </div>
            </v-card>

            <!--TOTALES-->
            <v-card
              width="100%"
              class="mx-2 mt-3 pa-4 pt-0"
            >
              <div class="d-flex justify-space-between align-center">
                <h4 class="my-1">
                  {{ $t('lbl.totalPay') }}
                </h4>
                <h4>
                  <h4>
                    {{ precioTotal | currency(currencySymbolFacturar) }}
                  </h4>
                </h4>
              </div>
              <fragment>
                <div class="d-flex justify-space-between align-center">
                  <h4 class="my-1">
                    {{ $t('lbl.payOnline') }}
                  </h4>
                  <h4>
                    {{ precioTotal | currency(currencySymbolFacturar) }}
                  </h4>
                </div>
                <div class="d-flex justify-space-between align-center ml-2">
                  <span
                    class="pb-0 mb-0 pt-0 mt-0"
                    style="font-size: 12px"
                  >
                    {{ $t('lbl.tarif') }}
                  </span>
                  <span
                    class="pb-0 mb-0 pt-0 mt-0"
                    style="font-size: 12px"
                  >
                    {{ precioTotal | currency(currencySymbolFacturar) }}
                  </span>
                </div>

                <div class="d-flex justify-space-between align-center">
                  <h4 class="my-1">
                    {{ $t('lbl.payAloja') }}
                  </h4>
                  <h4>
                    {{ 0 | currency(currencySymbolFacturar) }}
                  </h4>
                </div>
              </fragment>
            </v-card>

            <!--<v-card
              v-if="precioSuplementPayOnline > 0 || precioSuplementPayRentator > 0"
              width="100%"
              class="mx-2 mt-3 pa-4 pt-0"
            >
              <v-card-title
                primary-title
                class="px-0 py-2"
              >
                {{ $t('lbl.otherCarge') }}
              </v-card-title>
              <fragment v-if="precioSuplementPayOnline > 0">
                <div class="d-flex justify-space-between align-center">
                  <h4 class="my-1">
                    {{ $t('lbl.payOnline') }}
                  </h4>
                  <h4>
                    {{ precioSuplementPayOnline | currency }}
                  </h4>
                </div>
                <div class="d-flex justify-space-between align-center ml-2">
                  <h6>
                    {{ $t('lbl.seguro') }}
                  </h6>
                  <h6>
                    {{ cotizadorHotelsResultSelect.tarifaSeguro | currency }}
                  </h6>
                </div>
                <div
                  v-for="(suplemento, indSupl) in cotizadorHotelsResultSelect.suplementos"
                  :key="indSupl"
                  class="d-flex justify-space-between align-center ml-2"
                >
                  <fragment v-if="!!suplemento.online && !!suplemento.check">
                    <h6>
                      {{ suplemento.suplemento.name }}
                    </h6>
                    <h6>
                      {{ suplemento.priceAll | currency }}
                    </h6>
                  </fragment>
                </div>
              </fragment>

              <fragment v-if="precioSuplementPayRentator > 0">
                <div class="d-flex justify-space-between align-center">
                  <h4 class="my-1">
                    {{ $t('lbl.payRent') }}
                  </h4>
                  <h4>
                    {{ precioSuplementPayRentator | currency }}
                  </h4>
                </div>
                <div class="d-flex justify-space-between align-center ml-2">
                  <h6>
                    {{ $t('lbl.depositoGarantia') }}
                  </h6>
                  <h6>
                    {{ cotizadorHotelsResultSelect.tarifaGarantia | currency }}
                  </h6>
                </div>
                <div
                  v-for="(suplemento, indSupl) in cotizadorHotelsResultSelect.suplementos"
                  :key="indSupl"
                  class="d-flex justify-space-between align-center ml-2"
                >
                  <fragment v-if="!suplemento.online && !!suplemento.check">
                    <h6>
                      {{ suplemento.suplemento.name }}
                    </h6>
                    <h6>
                      {{ suplemento.priceAll | currency }}
                    </h6>
                  </fragment>
                </div>
              </fragment>
            </v-card>

            <v-card
              width="100%"
              class="mx-2 mt-3 pa-4 pt-0"
            >
              <div class="d-flex justify-space-between align-center">
                <h3 class="my-2">
                  {{ $t('lbl.totalPay') }}
                </h3>
                <h3>
                  <h4>
                    {{ precioTotal | currency }}
                  </h4>
                </h3>
              </div>
              <fragment v-if="precioSuplementPayOnline > 0">
                <div class="d-flex justify-space-between align-center">
                  <h4 class="my-1">
                    {{ $t('lbl.payOnline') }}
                  </h4>
                  <h4>
                    {{ (precioSuplementPayOnline + cotizadorHotelsResultSelect.priceTotal) | currency }}
                  </h4>
                </div>
                <div class="d-flex justify-space-between align-center ml-2">
                  <fragment>
                    <h6>
                      {{ $t('lbl.tarif') }}
                    </h6>
                    <h6>
                      {{ cotizadorHotelsResultSelect.priceTotal | currency }}
                    </h6>
                  </fragment>
                </div>
                <div class="d-flex justify-space-between align-center ml-2">
                  <fragment>
                    <h6>
                      {{ $t('lbl.cargoSuplement') }}
                    </h6>
                    <h6>
                      {{ precioSuplementPayOnline | currency }}
                    </h6>
                  </fragment>
                </div>
              </fragment>

              <fragment v-if="precioSuplementPayRentator > 0">
                <div class="d-flex justify-space-between align-center">
                  <h4 class="my-1">
                    {{ $t('lbl.payRent') }}
                  </h4>
                  <h4>
                    {{ precioSuplementPayRentator | currency }}
                  </h4>
                </div>
                <div class="d-flex justify-space-between align-center ml-2">
                  <fragment>
                    <h6>
                      {{ $t('lbl.cargoSuplement') }}
                    </h6>
                    <h6>
                      {{ precioSuplementPayRentator | currency }}
                    </h6>
                  </fragment>
                </div>
              </fragment>
            </v-card>-->
          </v-row>
        </template>

        <v-row>
          <!--MOSTRAR PARA EL CLIENTE-->
          <template v-if="!showOnlyGestor">
            <v-card
              width="100%"
              class="mx-2 pa-4"
            >
              <v-row>
                <v-col
                  cols="4"
                  class="pt-2"
                >
                  <h1
                    primary-title
                    class="pa-0"
                  >
                    PVP
                  </h1>
                </v-col>
                <v-col
                  cols="8"
                  class="pt-5 text-end"
                >
                  <AppBarCurrencyPVP />
                  <!--<span
                    style="font-size: 12px;"
                  >
                    ...{{ $t('lbl.facturar') }} {{ currency_symbol }} {{ currency_code }}
                  </span>-->
                </v-col>

                <!--TARIFAS-->
                <v-col
                  cols="12"
                  class="mt-0 pt-0"
                >
                  <div class="d-flex justify-space-between align-center">
                    <h4>
                      {{ $t('lbl.tarif') }}
                    </h4>
                    <h4>
                      <h4>
                        {{ precioTotal | currency(currencySymbolFacturar) }}
                      </h4>
                    </h4>
                  </div>
                </v-col>
                <v-col
                  v-for="(room, iRom) in cotizadorHotelsRomms"
                  :key="iRom"
                  cols="12"
                  class="mt-0 pt-0"
                >
                  <fragment v-if="!showOcupationRoomHotel && room.room_id">
                    <div class="d-flex justify-space-between align-center mb-0 pb-0">
                      <span
                        class="mb-0 pb-0"
                        style="font-size: 14px"
                      >
                        {{ room.cant }} x
                        {{
                          roomsNomAll.filter(e => e.id === room.room_id).length > 0
                            ? roomsNomAll.filter(e => e.id === room.room_id)[0].name
                            : ''
                        }}
                      </span>
                      <span style="font-size: 14px"> {{ room.priceWithDescuento | currency(currencySymbolFacturar) }} </span>
                    </div>
                    <div class="mt-0 pt-0">
                      <span
                        class="pt-0 mt-0 ml-5"
                        style="font-size: 10px"
                      >
                        {{ $t('lbl.plan') }}:
                        <span v-if="regimenNomAll.filter(e => e.id === room.plan).length > 0">
                          {{ regimenNomAll.filter(e => e.id === room.plan)[0].siglas }}
                        </span>
                        <span class="ml-1">{{ $t('lbl.uso') }}: </span>
                        <span class="text-uppercase">{{ room.uso }}</span>
                        <span v-if="room.cantTeenEdad + room.cantMnrEdad + room.cantInfEdad > 0">+ {{ $tc('lbl.cantChild', (room.cantTeenEdad + room.cantMnrEdad + room.cantInfEdad)) }}</span>
                      </span>
                    </div>
                  </fragment>
                </v-col>
              </v-row>
            </v-card>

            <!--SUPLEMENTOS-->
            <v-card
              width="100%"
              class="mx-2 mt-3 pa-4 pt-0"
            >
              <div class="d-flex justify-space-between align-center">
                <h4 class="my-1">
                  {{ $t('lbl.cargoSuplement') }}
                </h4>
                <h4>
                  <h4>
                    {{ 0 | currency(currencySymbolFacturar) }}
                  </h4>
                </h4>
              </div>
            </v-card>

            <!--TOTALES-->
            <v-card
              width="100%"
              class="mx-2 mt-2 pa-4"
            >
              <div class="d-flex justify-space-between align-center">
                <h4>
                  {{ $t('lbl.totalPay') }}
                </h4>
                <h4>
                  <h4>
                    {{ precioTotal | currency(currencySymbolFacturar) }}
                  </h4>
                </h4>
              </div>
              <fragment>
                <div class="d-flex justify-space-between align-center">
                  <h4>
                    {{ $t('lbl.payOnline') }}
                  </h4>
                  <h4>
                    {{ precioTotal | currency(currencySymbolFacturar) }}
                  </h4>
                </div>
                <div class="d-flex justify-space-between align-center ml-2">
                  <span
                    class="pb-0 mb-0 pt-0 mt-0"
                    style="font-size: 12px"
                  >
                    {{ $t('lbl.tarif') }}
                  </span>
                  <span
                    class="pb-0 mb-0 pt-0 mt-0"
                    style="font-size: 12px"
                  >
                    {{ precioTotal | currency(currencySymbolFacturar) }}
                  </span>
                </div>

                <div class="d-flex justify-space-between align-center">
                  <h4 class="my-1">
                    {{ $t('lbl.payAloja') }}
                  </h4>
                  <h4>
                    {{ 0 | currency(currencySymbolFacturar) }}
                  </h4>
                </div>
              </fragment>
              <!--
              <div class="d-flex justify-space-between align-center">
                <h4 class="my-2">
                  {{ $t('lbl.cargoSuplement') }}
                </h4>
              </div>
              <div class="d-flex justify-space-between align-center ml-2">
                <h6>
                  {{ $t('lbl.seguro') }}
                </h6>
                <h6>
                  {{ cotizadorHotelsResultSelect.tarifaSeguro | currency }}
                </h6>
              </div>
              <div class="d-flex justify-space-between align-center ml-2">
                <h6>
                  {{ $t('lbl.depositoGarantia') }}
                </h6>
                <h6>
                  {{ cotizadorHotelsResultSelect.tarifaGarantia | currency }}
                </h6>
              </div>
              <fragment>
                <div class="d-flex justify-space-between align-center ml-2">
                  <h6>
                    {{ $t('lbl.tarifaRetorno') }}
                  </h6>
                  <h6>
                    {{ precioTarifaRetorno | currency }}
                  </h6>
                </div>
              </fragment>
              <br />

              <div
                v-for="(suplemento, indSupl) in cotizadorHotelsResultSelect.suplementos"
                :key="indSupl"
                class="d-flex justify-space-between align-center ml-2"
              >
                <fragment
                  v-if="suplemento.suplemento.name.toLowerCase() !== 'tarifa de retorno' && suplemento.check === true"
                >
                  <h6>
                    {{ suplemento.suplemento.name }}
                  </h6>
                  <h6>
                    {{ suplemento.priceAll | currency }}
                  </h6>
                </fragment>
              </div>

              <div class="d-flex justify-space-between align-center">
                <h3 class="my-2">
                  {{ $t('lbl.totalPay') }}
                </h3>
                <h3>
                  <h4>
                    {{ precioTotal | currency }}
                  </h4>
                </h3>
              </div>-->
            </v-card>
          </template>

          <!--BOTONERA-->
          <v-card
            width="100%"
            class="mx-2 mt-3 pa-4 pt-0"
          >
            <v-container grid-list-xs>
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <template v-if="!isLoading">
                    <v-btn
                      color="primary"
                      width="100%"
                      block
                      :loading="loadingCart"
                      @click="addCarItem"
                    >
                      <v-icon class="mr-4">
                        {{ icons.mdiCartOutline }}
                      </v-icon>
                      {{ $t('btn.addToCar') }}
                    </v-btn>
                    <v-row>
                      <v-col
                        cols="12"
                        class="pt-5 text-end"
                      >
                        <AppBarCurrencyPVP />
                        <!--<span
                          style="font-size: 12px;"
                        >
                          ...{{ $t('lbl.facturar') }} {{ currency_symbol }} {{ currency_code }}
                        </span>-->
                      </v-col>
                    </v-row>
                  </template>
                  <v-skeleton-loader
                    v-else
                    class="mx-auto"
                    type="chip"
                    style="width: 100%;"
                  ></v-skeleton-loader>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-btn
                    color="primary"
                    width="100%"
                    outlined
                    @click="printItem"
                  >
                    {{ $t('btn.printCotization') }}
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-btn
                    color="primary"
                    width="100%"
                    outlined
                    @click="sendItem"
                  >
                    {{ $t('btn.sendCotization') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-row>
      </template>

      <v-row
        v-if="isLoading"
        class="my-2"
      >
        <v-card
          width="100%"
          class="mx-2 pa-4 pt-0"
        >
          <div class="align-center">
            <v-skeleton-loader
              v-for="(i, ind) in 4"
              :key="ind"
              class="mx-auto"
              type="table-tfoot"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="mx-auto"
              type="actions"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="mx-auto"
              type="actions"
            ></v-skeleton-loader>
          </div>
        </v-card>
      </v-row>
    </v-col>

    <!-- MOSTRAR PRINT ITEMS MORE ONE -->
    <v-dialog
      v-model="showModalPrint"
      scrollable
      max-width="480px"
    >
      <v-card max-height="480px">
        <v-card-title>{{ `${$t('btn.print')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <h3>{{ $t('lbl.titlePrintCar') }}</h3>
          <br />
          <v-radio-group
            v-model="printCotiItems"
            row
            hide-details
            class="mt-0"
          >
            <v-radio
              :label="$t('lbl.printCar1')"
              value="actual"
            ></v-radio>
            <v-radio
              :label="$t('lbl.printCar2')"
              value="all"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="showModalPrint = !showModalPrint"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :disabled="!printCotiItems"
            @click="confirmPrint"
          >
            {{ $t('btn.print') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- MOSTRAR SEND ITEMS MORE ONE -->
    <v-dialog
      v-model="showModalSend"
      scrollable
      max-width="480px"
    >
      <v-card max-height="480px">
        <v-card-title>{{ `${$t('lbl.sendCotization')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <h3>{{ $t('lbl.titleSendCar') }}</h3>
          <br />
          <v-radio-group
            v-model="printCotiItems"
            row
            hide-details
            class="mt-0"
          >
            <v-radio
              :label="$t('lbl.printCar1')"
              value="actual"
            ></v-radio>
            <v-radio
              :label="$t('lbl.printCar2')"
              value="all"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="showModalSend = !showModalSend"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :disabled="!printCotiItems"
            @click="confirmSend"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SidebarDataClient
      v-if="isSidebarDataClientActive"
      v-model="isSidebarDataClientActive"
      :from="btnName"
      :ctas-bancarias="ctasBancarias"
      @confirmPrintCotization="confirmPrintCotization"
      @confirmSend="confirmSend"
    />
    <SidebarSendCotization
      v-if="isSidebarSendCotizationActive"
      v-model="isSidebarSendCotizationActive"
      :name-company="nameCompany"
      :name-user="nameUser"
      :name-b2b="nameB2b"
      :email-user="emailUser"
      :user="user"
      @confirmSendCotization="confirmSendCotization"
      @showReturnDataClient="showReturnDataClient"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiCar,
  mdiBed,
  mdiSeatPassenger,
  mdiWalletTravel,
  mdiAirplaneTakeoff,
  mdiAirplane,
  mdiAirplaneLanding,
  mdiSwapHorizontal,
  mdiOfficeBuilding,
  mdiEye,
  mdiPencil,
  mdiDelete,
  mdiPlusThick,
  mdiChevronRight,
  mdiPlus,
  mdiMinus,
  mdiChevronLeft,
  mdiBagSuitcase,
  mdiBagPersonal,
  mdiInformation,
  mdiCartOutline,
} from '@mdi/js'

import { v4 as uuidv4 } from 'uuid'
// eslint-disable-next-line import/no-unresolved
import AppBarCurrencyPVP from '@/@core/layouts/components/app-bar/AppBarCurrencyPVP.vue'
import SidebarDataClient from './SidebarDataClient.vue'
import SidebarSendCotization from './SidebarSendCotization.vue'

// import Filtros from './Filtros.vue'
// import Ida from './Ida.vue'
// import IdaBack from './IdaBack.vue'
// import IdaDestinos from './IdaDestinos.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    SidebarDataClient,
    SidebarSendCotization,
    AppBarCurrencyPVP,
  },
  data() {
    return {
      priceMarkup: 0,
      priceDescuento: 0,
      itemsOrigen: [],
      isLoading: true,
      loading: false,
      onlyIda: false,
      multiplesDestinos: false,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      vrad: 0,
      roomsNomAll: [],
      markup: null,
      valueMarkup: null,
      descuento: null,
      valueDescuento: null,
      agregarDescuento: false,
      incluyeComition: 0,
      incluyeDescuento: 0,
      showOnlyGestor: true,
      icons: {
        mdiCar,
        mdiBed,
        mdiSeatPassenger,
        mdiWalletTravel,
        mdiAirplaneTakeoff,
        mdiAirplane,
        mdiAirplaneLanding,
        mdiSwapHorizontal,
        mdiOfficeBuilding,
        mdiEye,
        mdiPencil,
        mdiDelete,
        mdiPlusThick,
        mdiChevronRight,
        mdiPlus,
        mdiMinus,
        mdiChevronLeft,
        mdiBagSuitcase,
        mdiBagPersonal,
        mdiInformation,
        mdiCartOutline,
      },
      precioWithMUP: 0,
      precioWithDESC: 0,
      rooms: [],
      regimenNomAll: [],
      permisos: sessionStorage.getItem('permisos_auth'),

      nameB2b: null,
      nameCompany: '',
      nameUser: '',
      emailUser: '',
      btnName: '',
      isSidebarDataClientActive: false,
      isSidebarSendCotizationActive: false,
      printCotiItems: null,
      showModalPrint: false,
      showModalSend: false,
      ctasBancarias: [],
      currencyPVP: localStorage.getItem('currencyPVP'),
      currencyCodePVP: localStorage.getItem('currencyCodePVP'),
      markOrigin: JSON.parse(localStorage.getItem('markupsOrigin')),
      loadingCart: false,
    }
  },
  computed: {
    ...mapState({
      carItems: state => state.app.carItems,
      cotizadorHotelsRomms: state => state.app.cotizadorHotelsRomms,
      showOcupationRoomHotel: state => state.app.showOcupationRoomHotel,
      cotizadorHotels: state => state.app.cotizadorHotels,
      cotizadorHotelsResult: state => state.app.cotizadorHotelsResult,
      cotizadorHotelsResultSelect: state => state.app.cotizadorHotelsResultSelect,
      cotizadorHotelsSelectMarkups: state => state.app.cotizadorHotelsSelectMarkups,
      declineTerms: state => state.app.declineTerms,
      clientCarShop: state => state.app.clientCarShop,
      currency_code: state => state.app.currency_code,
      currency_symbol: state => state.app.currency_symbol,
      tasaCambioContrate: state => state.app.tasaCambioContrate,
      tasaCambioFacturar: state => state.app.tasaCambioFacturar,
      currencySymbolFacturar: state => state.app.currencySymbolFacturar,
      currencyCodeFacturar: state => state.app.currencyCodeFacturar,
    }),
    precioInitial() {
      let price = 0

      this.cotizadorHotelsRomms.forEach(ocupa => {
        price += ocupa.priceNeto
      })
      const priceTotal = (price / this.exchangeRateCurrencyContrate) * this.exchangeRateCurrencyFacturar

      return priceTotal
    },
    precioNeto() {
      let priceNeto = 0

      this.cotizadorHotelsRomms.forEach(ocupa => {
        priceNeto += ocupa.priceNeto
      })

      return priceNeto
    },
    precioWithMarkup() {
      let priceTotal = this.precioInitial

      if (this.markup) {
        sessionStorage.setItem('markup', this.markup)
        sessionStorage.setItem('valueMarkup', this.valueMarkup)

        // %
        if (this.valueMarkup === '%') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.precioWithMUP = (parseFloat(priceTotal) * parseFloat(this.markup)) / 100
          // eslint-disable-next-line no-mixed-operators
          priceTotal = parseFloat(priceTotal) + this.precioWithMUP
        // eslint-disable-next-line brace-style
        }

        // if (this.valueMarkup === this.currencyCodeFacturar) {
        else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.precioWithMUP = parseFloat(this.markup)
          priceTotal = parseFloat(priceTotal) + parseFloat(this.markup)
        }
      }

      return priceTotal
    },
    precioWithDescuento() {
      let priceTotal = this.precioWithMarkup

      /* if (this.markup) {
        // %
        if (this.valueMarkup === '%') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties, no-mixed-operators
          this.incluyeComition = (parseFloat(priceTotal) * parseFloat(this.markup)) / 100
          // eslint-disable-next-line no-mixed-operators
          priceTotal = parseFloat(priceTotal) + this.incluyeComition
        // eslint-disable-next-line brace-style
        }

        // if (this.valueMarkup === this.currencyCodeFacturar) {
        else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties, no-mixed-operators
          this.incluyeComition = parseFloat(this.markup)
          priceTotal = parseFloat(priceTotal) + this.incluyeComition
        }
      } */

      if (this.descuento) {
        sessionStorage.setItem('descuento', this.descuento)
        sessionStorage.setItem('valueDescuento', this.valueDescuento)

        // %
        if (this.valueDescuento === '%') {
          // eslint-disable-next-line no-mixed-operators, vue/no-side-effects-in-computed-properties
          this.incluyeDescuento = this.precioInitial * parseFloat(this.descuento) / 100

          // this.incluyeDescuento = (this.cotizadorHotelsResultSelect.minPrice * parseFloat(this.descuento)) / 100

          // this.incluyeDescuento = (parseFloat(priceTotal) * parseFloat(this.descuento)) / 100
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.precioWithDESC = this.incluyeDescuento
          // eslint-disable-next-line no-mixed-operators
          priceTotal = parseFloat(priceTotal) - this.incluyeDescuento
        // eslint-disable-next-line brace-style
        }

        // if (this.valueDescuento === this.currencyCodeFacturar) {
        else {
          // eslint-disable-next-line no-mixed-operators, vue/no-side-effects-in-computed-properties
          this.incluyeDescuento = parseFloat(this.descuento)
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.precioWithDESC = this.incluyeDescuento
          priceTotal = parseFloat(priceTotal) - this.incluyeDescuento
        }
      }

      return priceTotal
    },
    precioTotal() {
      let price = 0

      this.cotizadorHotelsRomms.forEach(ocupa => {
        price += ocupa.priceWithDescuento
      })

      return price

      /* + parseFloat(this.cotizadorHotelsResultSelect.tarifaSeguro)
        + parseFloat(this.cotizadorHotelsResultSelect.tarifaGarantia)

      this.cotizadorHotelsResultSelect.suplementos.forEach(suplement => {
        if (suplement.check) {
          priceTotal += suplement.priceAll
        }
      }) */
    },
    valueMarkupCalculate() {
      let priceTotal = this.precioInitial

      if (this.markup) {
        // %
        if (this.valueMarkup === '%') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.precioWithMUP = (parseFloat(priceTotal) * parseFloat(this.markup)) / 100
          // eslint-disable-next-line no-mixed-operators
          priceTotal = parseFloat(priceTotal) + this.precioWithMUP
        } // USD
        if (this.valueMarkup === this.currencyCodeFacturar) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.precioWithMUP = parseFloat(this.markup)
          priceTotal = parseFloat(priceTotal) + parseFloat(this.markup)
        }
      }

      return priceTotal - this.precioInitial
    },
    valueDescuentoCalculate() {
      /* let priceTotal = this.precioInitial

      if (this.markup) {
        // %
        if (this.valueMarkup === '%') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.precioWithMUP = (parseFloat(priceTotal) * parseFloat(this.markup)) / 100
          // eslint-disable-next-line no-mixed-operators
          priceTotal = parseFloat(priceTotal) + this.precioWithMUP
        } // USD
        if (this.valueMarkup === this.currencyCodeFacturar) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.precioWithMUP = parseFloat(this.markup)
          priceTotal = parseFloat(priceTotal) + parseFloat(this.markup)
        }
      } */

      let priceTotal = this.precioWithMarkup
      if (this.descuento) {
        // %
        if (this.valueDescuento === '%') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          priceTotal = this.precioWithMarkup - this.precioWithDescuento

          // this.priceDescuento = priceTotal - this.precioInitial - ((this.cotizadorHotelsResultSelect.minPrice * parseFloat(this.descuento)) / 100)
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          priceTotal = parseFloat(this.descuento)
        }
      } else {
        priceTotal = 0
      }

      return priceTotal
    },
    exchangeRateCurrencyContrate() {
      let result = 0
      if (localStorage.getItem('exchange_currency') !== null) {
        const exc = JSON.parse(localStorage.getItem('exchange_currency'))
        exc.exchanges.forEach(element => {
          if (element.currency_code === this.cotizadorHotelsResultSelect.contrate_currency_code) {
            result = element.value
          }
        })
      }

      return result
    },
    exchangeRateCurrencyFacturar() {
      let result = 0
      if (localStorage.getItem('exchange_currency') !== null) {
        const exc = JSON.parse(localStorage.getItem('exchange_currency'))
        exc.exchanges.forEach(element => {
          if (element.currency_code === this.currency_code) {
            result = element.value
          }
        })
      }

      return result
    },

    /* precioTarifaRetorno() {
      let priceTotal = 0

      this.cotizadorHotelsResultSelect.suplementos.forEach(suplement => {
        if (suplement.suplemento.name.toLowerCase() === 'tarifa de retorno' && suplement.check) {
          priceTotal = suplement.priceAll
        }
      })

      return priceTotal
    },
    precioSuplementPayOnline() {
      let priceTotal = parseFloat(this.cotizadorHotelsResultSelect.tarifaSeguro)

      this.cotizadorHotelsResultSelect.suplementos.forEach(suplement => {
        if (!!suplement.check && !!suplement.online) {
          priceTotal += suplement.priceAll
        }
      })

      return priceTotal
    },
    precioSuplementPayRentator() {
      let priceTotal = parseFloat(this.cotizadorHotelsResultSelect.tarifaGarantia)

      this.cotizadorHotelsResultSelect.suplementos.forEach(suplement => {
        if (!!suplement.check && !suplement.online) {
          priceTotal += suplement.priceAll
        }
      })

      return priceTotal
    }, */

    generateJsonCar() {
      const suplementos = []

      /* this.cotizadorHotelsResultSelect.suplementos.forEach(element => {
        if (element.check) {
          suplementos.push(element)
        }
      }) */

      // PAY TOTAL
      /* this.cotizadorHotelsResultSelect.suplementos.forEach(suplement => {
        if (suplement.check) {
          priceTotal += suplement.priceAll
        }
      }) */

      // eslint-disable-next-line no-unused-vars
      // let priceTotal = 0
      let priceInitial = 0
      this.cotizadorHotelsRomms.forEach(ocupa => {
        // priceTotal += ocupa.priceWithDescuento
        priceInitial += ocupa.price
      })

      /* let priceWithDescuento = priceTotal
      if (this.markup) {
        // %
        if (this.valueMarkup === '%') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties, no-mixed-operators
          this.incluyeComition = (parseFloat(priceTotal) * parseFloat(this.markup)) / 100
          // eslint-disable-next-line no-mixed-operators
          priceTotal = parseFloat(priceTotal) + this.incluyeComition
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties, no-mixed-operators
          this.incluyeComition = parseFloat(this.markup)
          priceTotal = parseFloat(priceTotal) + this.incluyeComition
        }
      }

      if (this.descuento) {
        // %
        if (this.valueDescuento === '%') {
          // eslint-disable-next-line no-mixed-operators, vue/no-side-effects-in-computed-properties
          this.incluyeDescuento = (this.cotizadorHotelsResultSelect.minPrice * parseFloat(this.descuento)) / 100
          // eslint-disable-next-line no-mixed-operators
          priceWithDescuento = parseFloat(priceTotal) - this.incluyeDescuento
        } else {
          // eslint-disable-next-line no-mixed-operators, vue/no-side-effects-in-computed-properties
          this.incluyeDescuento = parseFloat(this.descuento)
          priceWithDescuento = parseFloat(priceTotal) - this.incluyeDescuento
        }
      } */

      const payOnline = this.precioTotal

      const payRentadora = 0

      /* this.cotizadorHotelsResultSelect.suplementos.forEach(suplement => {
        if (!!suplement.check && !suplement.online) {
          payRentadora += suplement.priceAll
        }
      }) */

      const mark = {
        markup: 0,
        valueMarkup: 0,
        descuento: 0,
        valueDescuento: 0,
      }

      mark.valueMarkup = '%'
      if (!this.cotizadorHotelsSelectMarkups.valueMarkup === this.currencyCodePVP) {
        mark.valueMarkup = this.currencyCodePVP
      }
      if (this.cotizadorHotelsSelectMarkups.markup && this.valueMarkup === '%') {
        mark.markup = this.cotizadorHotelsSelectMarkups.markup
      } else if (this.cotizadorHotelsSelectMarkups.markup && this.valueMarkup === this.currencyCodePVP) {
        mark.markup = this.cotizadorHotelsSelectMarkups.markup * this.cotizadorHotelsResultSelect.diasReservar
      }

      const roomsNew = []

      // console.log('rooms', this.cotizadorHotelsResultSelect.rooms)
      // console.log('cotizadorHotelsRomms', this.cotizadorHotelsRomms)
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.cotizadorHotelsResultSelect.rooms.length; index++) {
        this.cotizadorHotelsRomms.forEach(element => {
          if (this.cotizadorHotelsResultSelect.rooms[index].cantAdulEdad === element.cantAdulEdad
          && this.cotizadorHotelsResultSelect.rooms[index].cantMnrEdad === element.cantMnrEdad
          && this.cotizadorHotelsResultSelect.rooms[index].cantInfEdad === element.cantInfEdad
          && this.cotizadorHotelsResultSelect.rooms[index].cantTeenEdad === element.cantTeenEdad
          && this.cotizadorHotelsResultSelect.rooms[index].plan === element.plan
          && this.cotizadorHotelsResultSelect.rooms[index].room_id === element.room_id
          ) {
            roomsNew.push({
              ...this.cotizadorHotelsResultSelect.rooms[index],
              priceNeto: parseFloat(element.priceNeto / element.cant),
              priceTotal: parseFloat(element.priceTotal / element.cant),
              priceWithMarkups: parseFloat(element.priceWithMarkups / element.cant),
              priceWithDescuento: parseFloat(element.priceWithDescuento / element.cant),
            })
          }
        })
      }

      const jsonItem = {
        hotel: this.cotizadorHotelsResultSelect.hotel,
        rooms: roomsNew,

        // rooms: this.cotizadorHotelsResultSelect.rooms,
        // rooms: this.cotizadorHotelsRomms,
        ocupation: this.cotizadorHotelsRomms,
        contrate_id: this.cotizadorHotelsResultSelect.contrate_id,
        currency: this.cotizadorHotelsResultSelect.currency,
        currencyCode: this.cotizadorHotelsResultSelect.currencyCode,
        code_tarifa: this.cotizadorHotelsResultSelect.code_tarifa,
        code_hotel: this.cotizadorHotelsResultSelect.code_hotel,
        tipo_reserva: this.cotizadorHotelsResultSelect.tipo_reserva,
        diasReservar: this.cotizadorHotelsResultSelect.diasReservar,
        priceInitial,
        priceTotal: payOnline,
        pricePayOnline: payOnline,
        proveedor: this.cotizadorHotelsResultSelect.proveedor,
        sources: this.cotizadorHotelsResultSelect.sources,
        change_currency: this.cotizadorHotelsResultSelect.change_currency,
        suplementos,

        // NEW
        priceNeto: this.precioNeto,
        contrateCurrencySymbol: this.cotizadorHotelsResultSelect.contrate_currency_symbol,
        contrateCurrencyCode: this.cotizadorHotelsResultSelect.contrate_currency_code,
        currencyBaseCode: this.cotizadorHotelsResultSelect.currencyBaseCode,
        tasaCambioContrate: this.tasaCambioContrate,
        tasaCambioFacturar: this.tasaCambioFacturar,
        currencySymbolFacturar: this.currencySymbolFacturar,
        currencyCodeFacturar: this.currencyCodeFacturar,
      }

      const jsonReserve = {
        ...this.cotizadorHotels,
        items: jsonItem,
      }

      const jsonPrices = {
        /* priceTotal: priceWithDescuento,
        pricePayOnline: priceWithDescuento, */

        priceInitial,
        priceTotal: payOnline,
        pricePayOnline: payOnline,
        precioSuplementPayOnline: payOnline,
        precioSuplementPayRentator: payRentadora,
        diasReservar: this.cotizadorHotelsResultSelect.diasReservar,
        contrate_id: this.cotizadorHotelsResultSelect.contrate_id,
        proveedor: this.cotizadorHotelsResultSelect.proveedor,
        markups: mark,
        descuento_v: this.descuento,
        descuento_value: this.valueDescuento,
        markup_v: this.markup,
        markup_value: this.valueMarkup,
        suplementos,

        // NEW
        priceNeto: this.precioNeto,
        currencyBaseCode: this.cotizadorHotelsResultSelect.currencyBaseCode,
        contrateCurrencySymbol: this.cotizadorHotelsResultSelect.contrate_currency_symbol,
        contrateCurrencyCode: this.cotizadorHotelsResultSelect.contrate_currency_code,
        tasaCambioContrate: this.tasaCambioContrate,
        tasaCambioFacturar: this.tasaCambioFacturar,
        currencySymbolFacturar: this.currencySymbolFacturar,
        currencyCodeFacturar: this.currencyCodeFacturar,
      }

      const json = {
        check: true,
        id: uuidv4(),
        product: 'hotels',
        data_client: this.clientCarShop,
        data_markups: jsonPrices,
        data_service: jsonReserve,
        data_reserva: {},
        lang: this.$i18n.locale,
        user_id: this.user.id,
      }

      return json
    },
  },
  created() {
    this.getRooms()
    this.getRegimen()
    this.profile()

    /* const mark = {
      markup: 0,
      valueMarkup: 0,
      descuento: 0,
      valueDescuento: 0,
    }

    this.setCotizadorHotelsSelectMarkups(mark) */

    this.valueMarkup = '%'
    if (!this.cotizadorHotelsSelectMarkups.valueMarkup === this.currencyCodePVP) {
      this.valueMarkup = this.currencyCodePVP
    }

    if (this.cotizadorHotelsSelectMarkups.markup && this.valueMarkup === '%') {
      this.markup = this.cotizadorHotelsSelectMarkups.markup
    } else if (this.cotizadorHotelsSelectMarkups.markup && this.valueMarkup === this.currencyCodePVP) {
      this.markup = this.cotizadorHotelsSelectMarkups.markup * this.cotizadorHotelsResultSelect.diasReservar
    }

    this.applyMarkups()

    window.addEventListener('scroll', this.handleScroll)

    if (this.permisos.includes('pvp:list')) {
      window.addEventListener('keydown', e => {
        // if ((e.key === 'r' || e.key === 'R') && e.shiftKey)
        if ((e.key === 'r' || e.key === 'R') && e.altKey) {
          this.changePrice()
        }
      })
    } else {
      this.changePrice()
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    ...mapMutations([
      'adicionarItemToCar',
      'setCotizadorHotelsSelectMarkups',
      'setCotizadorHotelSelectPrice',
      'updateLoadingBtn',
      'setCotizadorHotelsResult',
      'setCotizadorHotelsResultPaginate',
      'setCotizadorHotelsResultSelect',
      'setSearchingHotel',
      'updateItemToCar',
      'emptyClientCarShop',
      'updateCotizadorHotelsRomms',
    ]),
    setPrecioMarkup() {
      let priceTotal = this.precioInitial

      if (this.markup) {
        // %
        if (this.valueMarkup === '%') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.precioWithMUP = (parseFloat(priceTotal) * parseFloat(this.markup)) / 100
          // eslint-disable-next-line no-mixed-operators
          priceTotal = parseFloat(priceTotal) + this.precioWithMUP
        } // USD
        if (this.valueMarkup === this.currencyCodePVP) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.precioWithMUP = parseFloat(this.markup)
          priceTotal = parseFloat(priceTotal) + parseFloat(this.markup)
        }
      }

      this.priceMarkup = priceTotal - this.precioInitial
    },
    setPrecioDescuento() {
      let priceTotal = this.precioInitial

      if (this.markup) {
        // %
        if (this.valueMarkup === '%') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.precioWithMUP = (parseFloat(priceTotal) * parseFloat(this.markup)) / 100
          // eslint-disable-next-line no-mixed-operators
          priceTotal = parseFloat(priceTotal) + this.precioWithMUP
        } // USD
        if (this.valueMarkup === this.currencyCodePVP) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.precioWithMUP = parseFloat(this.markup)
          priceTotal = parseFloat(priceTotal) + parseFloat(this.markup)
        }
      }
      if (this.descuento) {
        // %
        if (this.valueDescuento === '%') {
          this.priceDescuento = priceTotal - this.precioInitial - ((this.cotizadorHotelsResultSelect.minPrice * parseFloat(this.descuento)) / 100)
        } // USD
        if (this.valueDescuento === this.currencyCodePVP) {
          this.priceDescuento = parseFloat(this.descuento)
        }
      }
    },
    validateMarkupsInput() {
      const sending = this.verificarMarkupsOrigin()
      if (!sending) {
        this.$toast.error(this.$t('msg.markupsNoValid'))

        // RESTAURAR MARKUPS
        this.markup = this.markOrigin.markup
        if (this.markOrigin.is_pc) {
          this.valueMarkup = '%'
        } else {
          this.valueMarkup = this.currencyCodeFacturar
        }
      }
    },
    validateMarkupsRadio() {
      const sending = this.verificarMarkupsOrigin()
      if (!sending) {
        this.$toast.error(this.$t('msg.markupsNoValid'))
      }
    },
    validateDescuentoInput() {
      if (this.valueMarkupCalculate < this.valueDescuentoCalculate) {
        this.$toast.error(this.$t('msg.markupsNoValid'))
      }
    },

    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          } else {
            this.user = response.data.data
            this.nameCompany = this.user.company.name
            this.nameUser = `${this.user.name} ${this.user.apellidos}`
            this.emailUser = `${this.user.email}`
          }
        })
        .finally(() => {
          this.getConfigData()
        })
    },
    getConfigData() {
      this.axios
        .get('configuration/data-general', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success) {
            this.nameB2b = res.data.data.data.data.name
            if (res.data.data.data.data.ctas_bancarias) {
              this.ctasBancarias = res.data.data.data.data.ctas_bancarias
            }
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    handleScroll() {
      if (window.scrollY >= 60) {
        this.$refs.statiCard.classList.add('topMine')
      } else {
        this.$refs.statiCard.classList.remove('topMine')
      }
    },
    goBack() {
      this.$router.push({ name: 'cotizador' })
    },
    changePrice() {
      this.showOnlyGestor = !this.showOnlyGestor
    },
    changeAgregarDescuento(e) {
      if (e === null) {
        this.descuento = null
        this.valueDescuento = null
      } else {
        this.descuento = 0
        this.valueDescuento = '%'
      }
    },
    verificarMarkupsOrigin() {
      // const markOrigin = JSON.parse(localStorage.getItem('markupsOrigin'))
      let result = true

      if (this.valueMarkup === '%') {
        if (this.markOrigin.is_pc) {
          if (this.markOrigin.markup > this.markup) {
            result = false
          }
        }
      } else {
        // RECALCULAR EL MARKUPS
        // eslint-disable-next-line no-mixed-operators
        const newValue = parseInt((this.precioInitial * this.markOrigin.markup / 100), 10)
        if (newValue > this.markup) {
          result = false
        }
      }

      return result
    },
    applyMarkups() {
      sessionStorage.setItem('markup', this.markup)
      sessionStorage.setItem('valueMarkup', this.valueMarkup)
      sessionStorage.setItem('descuento', this.descuento)
      sessionStorage.setItem('valueDescuento', this.valueDescuento)

      const sending = this.verificarMarkupsOrigin()
      if (!sending) {
        this.$toast.error(this.$t('msg.markupsNoValid'))
      } else if (parseInt(this.precioInitial, 10) <= parseInt(this.precioWithDescuento, 10)) {
        this.setCotizadorHotelsSelectMarkups({
          markup: this.markup,
          valueMarkup: this.valueMarkup,
          descuento: this.descuento,
          valueDescuento: this.valueDescuento,
        })

        let cont = 0
        this.cotizadorHotelsRomms.forEach(element => {
          cont += element.cant
        })
        const descByRoom = ((this.precioWithDescuento - this.precioInitial) / cont).toFixed(2)

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.cotizadorHotelsRomms.length; i++) {
          this.cotizadorHotelsRomms[i].priceWithDescuento = this.cotizadorHotelsRomms[i].cant * descByRoom + ((this.cotizadorHotelsRomms[i].priceNeto / this.tasaCambioContrate) * this.tasaCambioFacturar)
        }
        this.updateCotizadorHotelsRomms(this.cotizadorHotelsRomms)

        this.setPrecioMarkup()
        this.setPrecioDescuento()
      } else {
        this.$toast.error(this.$t('msg.markupsNoValid'))
      }
    },
    getRooms() {
      this.axios
        .get('nom_habitacion_hotels?page=1&per_page=100000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.roomsNomAll = res.data.data
          }
        })
    },
    getRegimen() {
      this.axios
        .get('nom_regimen_alimenticio?page=1&per_page=100000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.regimenNomAll = res.data.data
          }
        })
    },

    addCarItem() {
      let procesar = true
      this.cotizadorHotelsRomms.forEach(element => {
        if (
          !element.paxs[0].name
          || !element.paxs[0].apellidos
          || !element.paxs[0].noDocument
        ) {
          procesar = false
        }
      })

      if (procesar) {
        const json = this.generateJsonCar

        // this.loadingCart = true

        /* const formData = new FormData()
        if (this.noDocumentConductorPrincipalScanner) {
          formData.append('noDocumentConductorPrincipalScanner', this.noDocumentConductorPrincipalScanner)
        }
        if (this.noDocumentConductorAditionalScanner) {
          formData.append('noDocumentConductorAditionalScanner', this.noDocumentConductorAditionalScanner)
        }
        formData.append('data', JSON.stringify(json)) */

        /* CART EN STORE
        const cars = localStorage.getItem('car-shop') ? JSON.parse(localStorage.getItem('car-shop')) : []
        cars.push(json)

        localStorage.setItem('car-shop', JSON.stringify(cars))
        this.adicionarItemToCar(json) */

        // CART EN BD
        this.axios
          .post('cart/add', { item: json }, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            if (res.data.success) {
              // LIMPIAR COTIZADOR
              this.clearCotizador('addToCard')
            }
          })
          .finally(() => {
            this.loadingCart = false
          })
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },

    clearCotizador(action) {
      if (action === 'print') {
        // LLEVARLO A LA VISTA DE PRINT
        setTimeout(() => {
          this.$router.push({ name: 'view-details' })
        }, 200)
      } else {
        // REGRESARLO AL COTIZADOR
        setTimeout(() => {
          this.goBack()
        }, 200)
      }
      sessionStorage.setItem('clear-cotization', 'hotels')
      this.setSearchingHotel(false)

      this.emptyClientCarShop()

      // LIMPIAR LAS FECHAS
      sessionStorage.removeItem('dateIn')
      sessionStorage.removeItem('dateOut')

      // this.updateItemToCar([])
      // this.setCotizadorHotelsResult([])
      // this.setCotizadorHotelsResultPaginate([])
      // this.setCotizadorHotelsResultSelect([])

      // localStorage.removeItem('car-shop')
    },

    printItem() {
      if (this.carItems.length > 1) {
        this.showModalPrint = true
      } else {
        this.printCotiItems = 'actual'
        this.confirmPrint()
      }
    },
    confirmPrint() {
      this.showModalPrint = false
      this.btnName = 'btn-print'
      setTimeout(() => {
        this.isSidebarDataClientActive = true
      }, 50)
    },
    confirmPrintCotization() {
      if (this.clientCarShop.name && (this.clientCarShop.email || this.clientCarShop.emails.length > 0)) {
        const procesar = true

        this.updateLoadingBtn(true)
        if (procesar) {
          const jsonCar = {
            items: [this.generateJsonCar],
            data_client: this.clientCarShop,
            action: 'print',
          }

          this.axios
            .post('reservations/generate-cotization', jsonCar, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(res => {
              if (res.data.success) {
                sessionStorage.setItem('code-cotiza', res.data.data.code)
                sessionStorage.setItem('action-cotiza', 'print')

                // LIMPIAR COTIZADOR
                this.clearCotizador('print')
              }
            })
            // eslint-disable-next-line no-return-assign
            .finally(() => {
              this.updateLoadingBtn(false)
              this.isSidebarDataClientActive = false
            })
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
    sendItem() {
      if (this.carItems.length > 1) {
        this.showModalSend = true
      } else {
        this.printCotiItems = 'actual'
        this.confirmSend()
      }
    },
    confirmSend() {
      if (this.clientCarShop.name && (this.clientCarShop.email || this.clientCarShop.emails.length > 0)) {
        this.showModalSend = false
        setTimeout(() => {
          this.isSidebarDataClientActive = false
          this.isSidebarSendCotizationActive = true
        }, 50)
      } else {
        this.showModalSend = false
        this.btnName = 'btn-send'
        setTimeout(() => {
          this.isSidebarDataClientActive = true
        }, 50)
      }
    },
    showReturnDataClient() {
      setTimeout(() => {
        this.isSidebarDataClientActive = true
        this.isSidebarSendCotizationActive = false
      }, 50)
    },
    confirmSendCotization() {
      const procesar = true
      if (procesar) {
        this.updateLoadingBtn(true)
        const jsonCar = {
          items: [this.generateJsonCar],
          data_client: this.clientCarShop,
          action: 'send',
        }

        this.axios

        // .post('reservations/generate-cotization-send', jsonCar, {
          .post('reservations/generate-cotization', jsonCar, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            if (res.data.success) {
              this.$toast.success(this.$t('msg.sendCotization'))

              // LIMPIAR COTIZADOR
              setTimeout(() => {
                this.clearCotizador('send')
              }, 100)
            }
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => {
            this.updateLoadingBtn(false)
            this.isSidebarSendCotizationActive = false
          })
      }
    },
  },
}
</script>

<style>
.topMine {
  top: 70px !important;
}

@keyframes example {
  from {
    top: auto;
  }

  to {
    top: 70px;
  }
}

.topMine {
  animation-name: example;
  transition-duration: 2s;
  animation-duration: 1s;
  scroll-behavior: smooth;
}

#titleDispVueloMine {
  background-color: #2d3137;
  color: white;
  padding: 10px 12px;
}

#titleDispVueloMine > div > p {
  color: white !important;
  font-size: 14px;
}

#titleDispVueloMine > div > h5 {
  color: white !important;
}

#fromToHeadMine p {
  font-size: 14px;
}

#expandDispMine p {
  font-size: 12px;
  padding: 5px 0px;
}

.v-timeline:before {
  bottom: 0;
  content: '';
  height: 25%;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

#timeLineMovilMine.v-timeline:before {
  bottom: 0;
  content: '';
  height: 35%;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

.p14Mine {
  font-size: 14px !important;
}

.p12Mine {
  font-size: 12px !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > div.col-md-8.col-12
  > div:nth-child(3)
  > div
  > div.container.grid-list-xs
  > div
  > div:nth-child(6)
  > div {
  margin-top: 5px;
}

::v-deep .descuento .v-label {
  font-size: 14px !important;
}
::v-deep .v-skeleton-loader {
  width: 100%;
}
</style>
