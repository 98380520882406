import { render, staticRenderFns } from "./HotelRoom.vue?vue&type=template&id=41ca04be&"
import script from "./HotelRoom.vue?vue&type=script&lang=js&"
export * from "./HotelRoom.vue?vue&type=script&lang=js&"
import style0 from "./HotelRoom.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VCol,VIcon,VListItem,VListItemContent,VListItemTitle,VRadio,VRadioGroup,VRow,VSelect,VSkeletonLoader,VSwitch})
